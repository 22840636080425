import dynamicAPI from './dynamicAPI'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {useMessage} from "../context/AdminMessageContext";

const url = '/api/auth/userMachines'

const queryKey = 'userMachines'

export default function useUserMachinesHook(props) {
    const {page = 1, id, q = '', limit = 25} = props
    const queryClient = useQueryClient()
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getUserMachines = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the userMachines.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    )

    const getUserMachineById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the userMachine.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        { retry: 0, enabled: !!id }
    )

    const updateUserMachine = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserMachine updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update UserMachine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating the UserMachine.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteUserMachine = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserMachine deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete UserMachine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting the UserMachine.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postUserMachine = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserMachine created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create UserMachine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the UserMachine.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getUserMachines,
        updateUserMachine,
        deleteUserMachine,
        postUserMachine,
        getUserMachineById
    }
}
