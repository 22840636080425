import React from 'react';
import DeleteItemIcon from '../../../media/delete-item.svg';
import EditItemIcon from '../../../media/edit-item.svg';
import { Spinner } from '../../../components';

export const ViewMachinePriceRules = ({
                                          data,
                                          editHandler,
                                          deleteHandler,
                                          isLoadingDelete,
                                          isLoadingPriceRules,
                                          isError,
                                          error,
                                          useTranslation,
                                      }) => {
    const { t } = useTranslation();

    if (isLoadingPriceRules) return <Spinner />;
    if (isError) return <div className="alert alert-danger">{error.message}</div>;

    const rules = data?.data || [];

    return (
        <div className="table-responsive bg-light p-3 mt-2">
            <table className="table table-sm table-border edit-grid-view">
                <thead className="border-0">
                <tr>
                    <th>{t('Machine Name')}</th>
                    <th>{t('Machine ID')}</th>
                    <th>{t('Actions')}</th>
                </tr>
                </thead>
                <tbody>
                {rules.length > 0 ? (
                    rules.map((rule) => (
                        rule.machine ? (
                        <tr key={rule._id}>
                            <td>{rule.machine.machine_name}</td>
                            <td>{rule.machine.kiosk_external_id}</td>
                            <td>
                                <div className="btn-group">
                                    <button
                                        className="btn"
                                        onClick={() => editHandler(rule)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#machinePriceRuleModal"
                                    >
                                        <img src={EditItemIcon} alt="Edit" className="icon-img action-icons-img"/>
                                    </button>

                                    <button
                                        className="btn"
                                        onClick={() => deleteHandler(rule._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className="spinner-border spinner-border-sm"/>
                                        ) : (
                                            <img src={DeleteItemIcon} alt="Delete"
                                                 className="icon-img action-icons-img"/>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                        ) : (
                            <tr key={rule._id}>
                                <td colSpan="4">Machine data missing for this rule</td>
                            </tr>
                        )
                    ))
                ) : (
                    <tr>
                        <td colSpan="7" className="text-center">
                            {t('No price rules found.')}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};

export default ViewMachinePriceRules;
