// import moment from 'moment'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import ReFetchIcon from "../../../media/re-fetch.svg";
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";
import EditRulesIcon from "../../../media/edit-item-rules.svg";

import {useState} from "react";
import {useTranslation} from "react-i18next";
import moment from 'moment'

export const ViewMachines = ({
                                 data,
                                 editHandler,
                                 deleteHandler,
                                 isLoadingDelete,
                                 getMachineTotalStockById,
                                 getMachineTotalPriceById,
                             }) => {


    const {t} = useTranslation(); // Use i18n instance to access current language

    // console.log(data);
    const [stockValues, setStockValues] = useState({}); // State to hold stock values keyed by machine ID
    const [priceValues, setPriceValues] = useState({}); // State to hold prices values keyed by machine ID

    const handleFetchAndDisplayStock = async (machineId) => {
        const response = await getMachineTotalStockById(machineId);
        setStockValues(prev => ({...prev, [machineId]: response.totalCurrentStock}));
    };
    const handleFetchAndDisplayPrice = async (machineId) => {
        const response = await getMachineTotalPriceById(machineId);
        setPriceValues(prev => ({...prev, [machineId]: response.totalCurrentPrice}));
    };

    const openRulesTab = (machine_id) => {
        // Ensure you're using the correct URL and machine_id
        const url = `/admin/auth/machine-price-rules?machine_id=${machine_id}`;

        // Open a new tab by specifying '_blank' and avoiding window features like width, height, etc.
        window.open(url, '_blank');
    };

    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    {/*<th>Created</th>*/}
                    <th>Enabled</th>

                    <th>Kiosk External ID</th>
                    <th>Friendly Name</th>
                    {/*<th>Type</th>*/}
                    {/*<th>Items</th>*/}
                    <th>Order Processing ID</th>
                    {/*<th>Items Errors</th>*/}
                    {/*<th>Pre Items</th>*/}
                    {/*<th>Post Items</th>*/}
                    {/*<th>Pre Items Errors</th>*/}
                    {/*<th>Post Items Errors</th>*/}
                    <th>{t("Total Stock")}</th>
                    <th>{t("Total Value")}</th>
                    <th>Machine Status</th>
                    <th>MAGEX Activity</th>
                    <th>Kiosk Activity</th>
                    {/*<th>Blocked</th>*/}
                    <th>{t("Actions")}</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((machine) => {

                            const machineIsOlderThanFiveMinutes = moment().diff(moment(machine.last_machine_connection), 'minutes') > 5;
                            const connectionMachineActivityClass = machineIsOlderThanFiveMinutes ? 'outdated-connection' : '';
                            const kioskIsOlderThanFiveMinutes = moment().diff(moment(machine.last_kiosk_activity), 'minutes') > 5;
                            const connectionKioskActivityClass = kioskIsOlderThanFiveMinutes ? 'outdated-connection' : '';


                            return (
                                <tr key={machine._id}
                                    className={(machine.title === '' || machine.date === '' || machine.address === '') ? 'empty-values' : ''}>
                                    {/*<td>{moment(machine.createdAt).format('lll')}</td>*/}
                                    <td>
                                        {machine.confirmed ? (
                                            <FaCheckCircle className='text-success'/>
                                        ) : (
                                            <FaTimesCircle className='text-danger'/>
                                        )}
                                    </td>
                                    <td>{machine.kiosk_external_id}</td>
                                    <td>{machine.machine_name}</td>
                                    {/*<td>{machine.type}</td>*/}
                                    {/*<td>{JSON.stringify(machine.items)}</td>*/}
                                    <td>{machine.machine_order_id_processing}</td>
                                    <td onClick={() => handleFetchAndDisplayStock(machine._id)}>
                                        {
                                            stockValues[machine._id] !== undefined ?
                                                <div className="total-stock-machine">{stockValues[machine._id]}</div> :
                                                <div className="fetch-total-stock">
                                                    <img className="refetch-icon" src={ReFetchIcon} alt="Fetch Icon"/>
                                                </div>
                                        }
                                    </td>
                                    <td onClick={() => handleFetchAndDisplayPrice(machine._id)}>
                                        {
                                            priceValues[machine._id] !== undefined ?
                                                <div className="total-price-machine">{priceValues[machine._id]}</div> :
                                                <div className="fetch-total-price">
                                                    <img className="refetch-icon" src={ReFetchIcon} alt="Fetch Icon"/>
                                                </div>
                                        }
                                    </td>
                                    {/*<td>{JSON.stringify(machine.items_errors)}</td>*/}
                                    {/*<td>{JSON.stringify(machine.pre_items)}</td>*/}
                                    {/*<td>{JSON.stringify(machine.post_items)}</td>*/}
                                    {/*<td>{JSON.stringify(machine.pre_items_errors)}</td>*/}
                                    {/*<td>{JSON.stringify(machine.post_items_errors)}</td>*/}
                                    <td>{machine.machine_status}</td>
                                    <td className={connectionMachineActivityClass}>{moment(machine.last_machine_connection).format('MMM D, h:mm A')}</td>
                                    <td className={connectionKioskActivityClass}>{moment(machine.last_kiosk_activity).format('MMM D, h:mm A')}</td>
                                    {/*<td>*/}
                                    {/*  {machine.blocked ? (*/}
                                    {/*    <FaCheckCircle className='text-success' />*/}
                                    {/*  ) : (*/}
                                    {/*    <FaTimesCircle className='text-danger' />*/}
                                    {/*  )}*/}
                                    {/*</td>*/}

                                    <td>
                                        <div className='btn-group'>
                                            <button className="btn edit-rules-icon" onClick={() => openRulesTab(machine.kiosk_external_id)}>
                                                <img src={EditRulesIcon} alt="Edit Rules"
                                                     className="icon-img action-icons-img"/>
                                            </button>

                                            <button className="btn edit-item-icon"
                                                    onClick={() => editHandler(machine)} data-bs-toggle="modal"
                                                    data-bs-target="#machineModal">
                                                <img src={EditItemIcon} alt="Edit"
                                                     className="icon-img action-icons-img"/>
                                            </button>

                                            <button className="btn delete-item-icon"
                                                    onClick={() => deleteHandler(machine._id)}
                                                    disabled={isLoadingDelete}>
                                                {isLoadingDelete ? (
                                                    <span className="spinner-border spinner-border-sm"/>
                                                ) : (
                                                    <img src={DeleteItemIcon} alt="Delete"
                                                         className="icon-img action-icons-img"/>
                                                )}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    )) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch machine data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default ViewMachines
