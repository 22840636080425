import {Spinner, Message} from '../../index'
import {
    DynamicInputSelect,
} from '../../../utils/dynamicForm';

import AddMachinesToUser from './AddMachinesToUser';
export const FormUserMachines = ({
                                     edit,
                                     formCleanHandler,
                                     isLoading,
                                     register,
                                     isError,
                                     isErrorUpdate,
                                     errors,
                                     isLoadingUpdate,
                                     isLoadingPost,
                                     handleSubmit,
                                     submitHandler,
                                     error,
                                     dataMachines,
                                     dataUsers,
                                     setValue,
                                     watch,
                                     selectedMachines,
                                     setSelectedMachines
                                 }) => {

    // console.log(getDefaultCategoryOptions());
    // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    //   ...option,
    //   key: index.toString(),
    // }));

    // console.log('formUserMachines');
    // console.log(dataMachines);
    return (<div
            className='modal fade'
            id='userMachineModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='userMachineModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='userMachineModalLabel'>
                            {edit ? 'Set/Edit Machines' : 'Add machines to a User'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (<Spinner/>) : isError ? (
                            <Message variant='danger'>{error}</Message>) : isErrorUpdate ? (
                            <Message variant='danger'>{error}</Message>) : (
                            <form onSubmit={handleSubmit(submitHandler)}>
                                {DynamicInputSelect({
                                    register,
                                    errors,
                                    label: 'User',
                                    name: 'user',
                                    value: 'name',
                                    data:
                                        dataUsers &&
                                        dataUsers.data,
                                    placeholder: 'User',
                                })}
                                <AddMachinesToUser
                                    register={register}
                                    label={'machines'}
                                    name={"machines"}
                                    errors={errors}
                                    watch={watch}
                                    dataMachines={dataMachines}
                                    setValue={setValue}
                                    selectedMachines={selectedMachines}
                                    setSelectedMachines={setSelectedMachines}
                                />
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>) : ('Submit')}
                                    </button>
                                </div>
                            </form>)}
                    </div>
                </div>
            </div>
        </div>)
}

export default FormUserMachines
