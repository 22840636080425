import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/machines/price-rules';
const queryKey = 'machinePriceRules';

export default function useMachinePriceRulesHook(props) {
    const {page = 1, id, q = '', limit = 25} = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    // Always return an empty object if the request fails
    const getMachinePriceRules = useQuery(
        [queryKey, page, limit],
        async () => {
            try {
                const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
                if (response.error) throw new Error(response.error);
                return response;
            } catch (error) {
                setErrorMessage(error.message || 'An error occurred while fetching the price rules.');
                // Return empty data structure in case of failure
                return { data: [], total: 0 };
            }
        },
        {
            retry: 0,
            onError: (error) => {
                setErrorMessage(error.message || 'An error occurred while fetching the price rules.');
            },
        }
    );

    // Fetch a specific machine price rule by machine ID using `useQuery`
    const getMachinePriceRuleById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`, {});
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the price rule.';
                throw new Error(errorMessage); // Throw the error so React Query can handle it
            } else {
                return response;
            }
        },
        {
            retry: 0,
            enabled: !!id,
            onError: (error) => {
                setErrorMessage(error.message || 'An error occurred while fetching the price rule.');
            }
        }
    );
    // Update a specific machine price rule
    const updateMachinePriceRule = useMutation(
        async (obj) => {
            const response = await dynamicAPI('put', `${url}/${obj._id}`, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Machine Price Rule updated successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to update Machine Price Rule.');
            }
        }
    );

    // Delete a specific machine price rule
    const deleteMachinePriceRule = useMutation(
        async (id) => {
            const response = await dynamicAPI('delete', `${url}/${id}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Machine Price Rule deleted successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to delete Machine Price Rule.');
            }
        }
    );

    // Create a new machine price rule
    const postMachinePriceRule = useMutation(
        async (obj) => {
            const response = await dynamicAPI('post', url, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Machine Price Rule created successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to create Machine Price Rule .');
            }
        }
    );

    return {
        getMachinePriceRules,
        getMachinePriceRuleById,
        updateMachinePriceRule,
        deleteMachinePriceRule,
        postMachinePriceRule,
    };
}