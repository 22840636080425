import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import useProductsHook from '../../../api/products';
import { ViewProducts } from '../../../components/admin/products/ViewProducts';
import { FormProducts } from '../../../components/admin/products/FormProducts';
import {
    Spinner,
    Pagination,
    Message,
    Confirm,
    Search,
} from '../../../components';
import useCategoriesHook from "../../../api/productCategories";
import useBrandsHook from "../../../api/brands";
import { checkImageFiles } from '../../../utils/imageTools';
import { useTranslation } from "react-i18next";
import DefaultProductsIcon from "../../../media/vending-machine.svg";

const Products = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const {t} = useTranslation(); // Use i18n instance to access current language

    const { getProducts, postProduct, updateProduct, deleteProduct } = useProductsHook({ page, q });

    const { getProductCategories } = useCategoriesHook({ limit: 100000, page: 1 });
    const { data: categoriesData } = getProductCategories;

    const { getBrands } = useBrandsHook({ limit: 100000, page: 1 });
    const { data: brandsData } = getBrands;

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    });

    const { data, isLoading, isError, error, refetch } = getProducts;

    const { mutateAsync: mutateAsyncUpdate } = updateProduct;
    const { mutateAsync: mutateAsyncDelete } = deleteProduct;
    const { mutateAsync: mutateAsyncPost } = postProduct;

    const formCleanHandler = (force = false) => {
        if (force) {
            setEdit(false);
            reset();
            setFileInputs([]);
            setImageThumbnails([]);
        }
    };

    const [imageThumbnails, setImageThumbnails] = useState([]);
    const [imagesPath, setImagesPath] = useState({});
    const [fileInputs, setFileInputs] = useState([]);

    useEffect(() => {
        refetch();
    }, [page]);

    useEffect(() => {
        if (!q) refetch();
    }, [q]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1);
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)));
    };

    const submitHandler = async (itemData) => {
        try {
            const allImages = checkImageFiles(fileInputs);

            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    sku: itemData.sku,
                    barcode: itemData.barcode,
                    name: {
                        el: itemData.name_el,
                        en: itemData.name_en,
                    },
                    short_description: {
                        el: itemData.short_description_el,
                        en: itemData.short_description_en,
                    },
                    description: {
                        el: itemData.description_el,
                        en: itemData.description_en,
                    },
                    ingredients: {
                        el: itemData.ingredients_el,
                        en: itemData.ingredients_en,
                    },
                    allergens: {
                        el: itemData.allergens_el,
                        en: itemData.allergens_en,
                    },
                    price: itemData.price,
                    brand: itemData.brand,
                    sort_index: itemData.sort_index,
                    age_restriction: itemData.age_restriction,
                    age_restriction_message: itemData.age_restriction_message,
                    categories: itemData.categories,
                    images: allImages,
                    confirmed: itemData.confirmed,
                    blocked: itemData.blocked,
                    tags: itemData.tags,
                });
            } else {
                await mutateAsyncPost({
                    name: {
                        el: itemData.name_el,
                        en: itemData.name_en,
                    },
                    short_description: {
                        el: itemData.short_description_el,
                        en: itemData.short_description_en,
                    },
                    description: {
                        el: itemData.description_el,
                        en: itemData.description_en,
                    },
                    ingredients: {
                        el: itemData.ingredients_el,
                        en: itemData.ingredients_en,
                    },
                    allergens: {
                        el: itemData.allergens_el,
                        en: itemData.allergens_en,
                    },
                    ...itemData,
                    images: allImages,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const editHandler = (product) => {
        console.log('editHandler');
        console.log(product);
        setId(product._id)
        setEdit(true);

        setValue('sku', product.sku);
        setValue('barcode', product.barcode);

        if(product.name && typeof product.name === 'object') {
            Object.keys(product.name).forEach(lang => {
                setValue(`name_${lang}`, product.name[lang]);
            });
        }

        if(product.short_description && typeof product.short_description === 'object') {
            Object.keys(product.short_description).forEach(lang => {
                setValue(`short_description_${lang}`, product.short_description[lang]);
            });
        }


        if(product.description && typeof product.description === 'object') {
            Object.keys(product.description).forEach(lang => {
                setValue(`description_${lang}`, product.description[lang]);
            });
        }

        if(product.ingredients && typeof product.ingredients === 'object') {
            Object.keys(product.ingredients).forEach(lang => {
                setValue(`ingredients_${lang}`, product.ingredients[lang]);
            });
        }

        if(product.allergens && typeof product.allergens === 'object') {
            Object.keys(product.allergens).forEach(lang => {
                setValue(`allergens_${lang}`, product.allergens[lang]);
            });
        }

        // setValue('short_description', product.short_description);
        // setValue('description', product.description);
        setValue('price', product.price);
        // setValue('price_per_kilo', product.price_per_kilo);
        // setValue('price_with_tax', product.price_with_tax);
        setValue('brand', product.brand);
        setValue('sort_index', product.sort_index);
        // setValue('ingredients', product.ingredients);
        // setValue('allergens', product.allergens);
        setValue('age_restriction', product.age_restriction);
        setValue('age_restriction_message', product.age_restriction_message);
        // setValue('size', product.size);
        // setValue('category', product.category);
        setValue('categories', product.categories);
        setValue('images', product.images);
        setImageThumbnails(product.images)
        setImagesPath(data.images_path);
        setValue('confirmed', product.confirmed);
        setValue('blocked', product.blocked);
        // setValue('tags', product.tags);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Products</title>
                    <meta property="og:title" content="Products" key="title"/>
                </Helmet>
            </HelmetProvider>

            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    {t("Products List")} <sup className='fs-6'> [{data && data.total}] </sup>
                    <img className="products-list-title-icon admin-list-title-icon" src={DefaultProductsIcon} alt="Default Marker Icon" />
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#productModal'
                        onClick={() => formCleanHandler(true)}
                    >
                        {t("Add New Product")}
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder={t("Search by Code,Name")}
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>

            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant="danger">{error?.message || 'An unexpected error occurred.'}</Message>
            ) : (
                <ViewProducts
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    formCleanHandler={formCleanHandler}
                    useTranslation={useTranslation}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormProducts
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                setValue={setValue}
                imageThumbnails={imageThumbnails}
                images_path={imagesPath}
                setFileInputs={setFileInputs}
                fileInputs={fileInputs}
                categoriesData={categoriesData}
                brandsData={brandsData}
                useTranslation={useTranslation}
            />

        </>
    );
};


export default Products;
