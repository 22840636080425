import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import RequireAuth from './components/shared/RequireAuth';
import ForgotPassword from './screens/auth/ForgotPassword';
import Home from './screens/auth/Home';
import Login from './screens/auth/Login';
import ResetPassword from './screens/auth/ResetPassword';
import Profile from './screens/auth/Profile';
import ClientPermissions from './screens/admin/auth/ClientPermissions';
import Permissions from './screens/admin/auth/Permissions';
import Roles from './screens/admin/auth/Roles';
import UserRoles from './screens/admin/auth/UserRoles';
import UserProfiles from './screens/admin/auth/UserProfiles';
import Users from './screens/admin/auth/Users';
import Machines from './screens/admin/auth/Machines';
import MachinePriceRules from './screens/admin/auth/MachinePriceRules';
import Products from './screens/admin/auth/Products';
import Categories from './screens/admin/auth/ProductCategories';
import Brands from './screens/admin/auth/Brands';
import UserMachines from './screens/admin/auth/UserMachines';

import Orders from './screens/admin/auth/Orders';
import {Layout} from './components';
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Button} from "@mui/material";
import {AuthProvider} from './context/AuthProvider'
import {AdminMessageProvider} from './context/AdminMessageContext';

const Admin = () => {
    const navigate = useNavigate();


    return (
        <>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)}/>
                )}
                onError={console.error}
            >
                <AdminMessageProvider>

                    <AuthProvider>
                        <div className="admin-container">

                            <Layout showAdminNavigation={true}>
                                <Routes>
                                    {/* OPEN ROUTES*/}
                                    <Route path='/auth/login' element={<Login/>}/>
                                    <Route path='/auth/forgot-password' element={<ForgotPassword/>}/>
                                    <Route path='/auth/reset-password/:restToken' element={<ResetPassword/>}/>

                                    {/* AUTH ROUTES*/}
                                    <Route element={<RequireAuth/>}>
                                        <Route path='/' element={<Home/>}/>
                                        <Route path='/auth/account/profile' element={<Profile/>}/>
                                        <Route path='/auth/client-permissions' element={<ClientPermissions/>}/>
                                        <Route path='/auth/permissions' element={<Permissions/>}/>
                                        <Route path='/auth/categories' element={<Categories/>}/>
                                        <Route path='/auth/brands' element={<Brands/>}/>
                                        <Route path='/auth/roles' element={<Roles/>}/>
                                        <Route path='/auth/user-roles' element={<UserRoles/>}/>
                                        <Route path='/auth/user-profiles' element={<UserProfiles/>}/>
                                        <Route path='/auth/user-machines' element={<UserMachines/>}/>
                                        <Route path='/auth/users' element={<Users/>}/>
                                        <Route path='/auth/machines' element={<Machines/>}/>
                                        <Route path='/auth/machine-price-rules' element={<MachinePriceRules/>}/>
                                        <Route path='/auth/products' element={<Products/>}/>
                                        <Route path='/auth/orders' element={<Orders/>}/>
                                    </Route>
                                </Routes>
                            </Layout>
                        </div>
                    </AuthProvider>
                </AdminMessageProvider>
            </ErrorBoundary>
        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    const clearLocalStorage = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userRole');
        goBack(); // Trigger goBack after clearing local storage
    };


    return (
        <Layout showAdminNavigation={false}>

            <div className="main-user-error-message-block">
                <div className="user-error-message-block">
                    <p>Something went wrong 😭</p>
                    <span>Error: {errorMessage}</span>
                    <p>-</p>
                    <p>
                        <Link to={"/kiosk"} className="shop_go_to_shop_link">
                            GO TO SHOP
                        </Link>
                    </p>
                    <p>
                        <Button onClick={goBack}>Go Back</Button>
                    </p>
                    <p>
                        <Button onClick={clearLocalStorage}>
                            Clear Local Storage and Go Back
                        </Button>
                    </p>
                </div>
            </div>
        </Layout>

    );
}

export default Admin
