import { createContext, useReducer, useEffect, useRef, useCallback } from "react";

export const CheckoutContext = createContext();

const initialState = {
  countdown: 80,  // Default timeout duration for checkout
};

function checkoutReducer(state, action) {
  switch (action.type) {
    case 'START_CHECKOUT_COUNTDOWN':
      return { ...state, countdown: action.payload || 80 };
    case 'RESET_CHECKOUT_COUNTDOWN':
      return { ...state, countdown: 80 };
    case 'STOP_CHECKOUT_COUNTDOWN':
      return { ...state, countdown: 0 };
    default:
      return state;
  }
}

export const CheckoutContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);
  const countdownRef = useRef(state.countdown);
  const intervalRef = useRef(null);

  const startInterval = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (countdownRef.current > 0) {
        countdownRef.current -= 1;
      } else {
        clearInterval(intervalRef.current);
      }
    }, 1000);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const startCheckoutCountdown = useCallback((timeoutDuration = 80) => {
    dispatch({ type: 'START_CHECKOUT_COUNTDOWN', payload: timeoutDuration });
    countdownRef.current = timeoutDuration;
    startInterval();
  }, []);

  const resetCheckoutCountdown = useCallback((timeoutDuration = 80) => {
    dispatch({ type: 'RESET_CHECKOUT_COUNTDOWN' });
    countdownRef.current = timeoutDuration;
    startInterval();  // Restarts the interval
  }, []);

  const stopCheckoutCountdown = useCallback(() => {
    dispatch({ type: 'STOP_CHECKOUT_COUNTDOWN' });
    countdownRef.current = 0;
    clearInterval(intervalRef.current);  // Stops the interval
  }, []);

  const getCheckoutCountdown = () => countdownRef.current;

  return (
      <CheckoutContext.Provider value={{
        startCheckoutCountdown,
        resetCheckoutCountdown,
        stopCheckoutCountdown,
        getCheckoutCountdown
      }}>
        {children}
      </CheckoutContext.Provider>
  );
};
