import {Spinner, Message} from '../../index'
import {
    InputText,
} from '../../../utils/dynamicForm';
import {
    InputImagesBlock
} from "../../../utils/imageTools";
import {
    languageOptions
} from "../../../config/languagesConfig";

export const FormCategories = ({
                               edit,
                               formCleanHandler,
                               isLoading,
                               register,
                               isError,
                               isErrorUpdate,
                               errors,
                                setValue,
                                watch,
                                imageThumbnails,
                                images_path,
                                fileInputs,
                                setFileInputs,
                               isLoadingUpdate,
                               isLoadingPost,
                               handleSubmit,
                               submitHandler,
                               error,
                               // dataMachines,
                           }) => {


    // console.log(getDefaultCategoryOptions());
    // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    //   ...option,
    //   key: index.toString(),
    // }));

// console.log('formCategories' );
// console.log( dataMachines);

    return (
        <div
            className='modal fade'
            id='categoryModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='categoryModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='categoryModalLabel'>
                            {edit ? 'Edit Categories' : 'Add a NEW Category'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner/>
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : isErrorUpdate ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>
                                {InputText({
                                    register,
                                    errors,
                                    label: 'Category ID',
                                    name: 'category_id',
                                    placeholder: '',
                                    isRequired: true,
                                })}
                                {/*{inputText({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Category Name',*/}
                                {/*    name: 'category_name',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: true,*/}
                                {/*})}*/}


                                {languageOptions.map((lang) => InputText({
                                    register,
                                    errors,
                                    label: `Category Name`,
                                    name: `category_name_${lang.value}`,
                                    placeholder: "",
                                    lang: lang.value,
                                    isRequired: lang === 'el', // Make Greek mandatory
                                }))}

                                {InputImagesBlock({
                                    setValue,
                                    errors,
                                    watch,
                                    register,
                                    label: 'Images',
                                    name: 'images',
                                    placeholder: '',
                                    isRequired: false,
                                    imageThumbnails,
                                    images_path,
                                    fileInputs,
                                    setFileInputs,
                                    maxImages: 1

                                    // initialValue: watch('items'),
                                })}
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormCategories
