import axios from 'axios';
import { config } from '../utils/customLocalStorage';

const apiUrl = process.env.REACT_APP_BACKEND_URL || "https://not_set";

const dynamicAPI = async (method, url, obj = {}) => {
    try {
        let response;
        switch (method) {
            case 'get':
                response = await axios.get(`${apiUrl}${url}`, config());
                break;
            case 'post':
                response = await axios.post(`${apiUrl}${url}`, obj, config());
                break;
            case 'put':
                response = await axios.put(`${apiUrl}${url}`, obj, config());
                break;
            case 'delete':
                response = await axios.delete(`${apiUrl}${url}`, config());
                break;
            default:
                throw new Error('Invalid method');
        }
        return {
            ...response.data,
            status: response.status,
            isSuccess: true,
        };
    } catch (error) {
        console.error('BAD ERROR', error);
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
        if (error.response?.status === 401) {
            console.error('UNAUTHORIZED ERROR:', errorMessage);
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userRole');
        }
        if (error.response?.status === 403) {
            console.error('UNAUTHORIZED PERMISSION:', error);
            return {
                error: errorMessage,
                status: error.response?.status,
                isError: true,
                isSuccess: false
            };
        }
        // Rethrow the error so it can be caught in the `onError` of the mutation
        return {
            error: errorMessage,
            status: error.response?.status || 500,
            isError: true,
            isSuccess: false,
        };
    }
};

export default dynamicAPI;
