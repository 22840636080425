import dynamicAPI from './dynamicAPI'
import { useQuery } from 'react-query'

const usersUrl = '/api/auth/min/users'
const machinesUrl = '/api/auth/min/machines'
const productCategoriesUrl = '/api/auth/min/productCategories'

export default function useSimpleDataHook({ page = 1, q = '', limit = 25 }) {

    // Get Machines (with dynamic key)
    const getSimpleMachines = useQuery(
        ["simpleMachines", page, q, limit],  // Unique key based on page, q, and limit
        async () => {
            const response = await dynamicAPI('get', `${machinesUrl}?page=${page}&q=${q}&limit=${limit}`);
            return response;  // Make sure to return the response
        },
        { retry: 0 }  // No retry
    );

    // Get Users (with dynamic key)
    const getSimpleUsers = useQuery(
        ["simpleUsers", page, q, limit],  // Unique key based on page, q, and limit
        async () => {
            const response = await dynamicAPI('get', `${usersUrl}?page=${page}&q=${q}&limit=${limit}`);
            return response;  // Return the response
        },
        { retry: 0 }  // No retry
    );

    // Get Product Categories (with dynamic key)
    const getSimpleProductCategories = useQuery(
        ["simpleCategories", page, q, limit],  // Unique key based on page, q, and limit
        async () => {
            const response = await dynamicAPI('get', `${productCategoriesUrl}?page=${page}&q=${q}&limit=${limit}`);
            return response;  // Return the response
        },
        { retry: 0 }  // No retry
    );

    return {
        getSimpleMachines,
        getSimpleUsers,
        getSimpleProductCategories,
    };
}
