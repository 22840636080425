import React, { useContext, useEffect, useState } from 'react';
import { CheckoutContext } from '../../context/CheckoutContext';
import '../../css/CheckoutCountdownDisplay.css';

function CheckoutCountdownDisplay() {
    const { getCheckoutCountdown } = useContext(CheckoutContext);
    const [countdown, setCountdown] = useState(getCheckoutCountdown());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(getCheckoutCountdown());
        }, 1000);

        return () => clearInterval(interval);
    }, [getCheckoutCountdown]);

    return (
        <div className="checkout-countdown-display">
            {`${countdown}s`}
        </div>
    );
}

export default CheckoutCountdownDisplay;
