import dynamicAPI from './dynamicAPI';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {useMessage} from '../context/AdminMessageContext';

const url = '/api/auth/brands';
const queryKey = 'brands';

export default function useBrandsHook(props) {
    const {page = 1, id, q = '', limit = 25} = props;
    const queryClient = useQueryClient();
    const {setSuccessMessage, setErrorMessage} = useMessage(); // Use the message context

    const getBrands = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the brands.';
                setErrorMessage(errorMessage);

            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15
        }
    );

    const getBrandById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`, {});

            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the brand.';
                setErrorMessage(errorMessage);

            } else {
                return response;
            }
        },
        {
            retry: 0,
            enabled: !!id
        }
    );

    const updateBrand = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Brand updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update Brand.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating Brand.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteBrand = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Brand deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete Brand.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting Brand.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postBrand = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Brand created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create Brand.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating Brand.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getBrands,
        updateBrand,
        deleteBrand,
        postBrand,
        getBrandById,
    };
}
