import {Spinner, Message} from '../../index'
import {
    InputCheckBox,
    InputText,
    StaticInputSelect,
} from '../../../utils/dynamicForm';
import InputGridOfEntriesBlock from './PlanogramFormMachines';
export const FormMachines = ({
                                 edit,
                                 formCleanHandler,
                                 isLoading,
                                 register,
                                 isError,
                                 errors,
                                 watch,
                                 setValue,
                                 isLoadingUpdate,
                                 isLoadingPost,
                                 handleSubmit,
                                 submitHandler,
                                 triggerReset,
                                 error,
                                 dataProducts,
    
                                 // dataMachines,
                             }) => {

    // console.log(getDefaultCategoryOptions());
    // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    //   ...option,
    //   key: index.toString(),
    // }));
    //     console.log('dataMachines');
    // console.log(dataMachines)

    return (
        <div
            className='modal fade'
            id='machineModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='machineModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='machineModalLabel'>
                            {edit ? 'Edit Machines' : 'Add a NEW Machine'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner/>
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>
                                <div className='machine-info form-group-block'>
                                    <div className='machine-info-title form-group-title'>Info</div>

                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Kiosk External ID',
                                        name: 'kiosk_external_id',
                                        placeholder: '',
                                        isRequired: true,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Friendly Name',
                                        name: 'machine_name',
                                        placeholder: '',
                                        isRequired: true,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Phone',
                                        name: 'machine_phone',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Erp Client ID',
                                        name: 'erp_id',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Erp Token',
                                        name: 'erp_token',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Erp Document Series',
                                        name: 'erp_doc_series',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                </div>

                                <div className='machine-planogram form-group-block'>
                                    <div className='machine-planogram-title form-group-title'>Planogram</div>

                                    {InputGridOfEntriesBlock({
                                        setValue,
                                        errors,
                                        watch,
                                        register,
                                        label: 'Items',
                                        name: 'items',
                                        placeholder: '',
                                        isRequired: false,
                                        dataProducts,
                                        triggerReset
                                        // initialValue: watch('items'),
                                    })}
                                    {InputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        name: 'enablePriceRules',
                                        label: 'Enable Price Rules',
                                        isRequired: false,
                                        placeholder: 'Confirmed',
                                    })}
                                </div>

                                {/*{inputTextArea({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Items Errors',*/}
                                {/*    name: 'items_errors',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: false,*/}
                                {/*})}*/}
                                {/*{inputTextArea({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Pre Items',*/}
                                {/*    name: 'pre_items',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: false,*/}
                                {/*})}*/}
                                {/*{inputTextArea({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Post Items',*/}
                                {/*    name: 'post_items',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: false,*/}
                                {/*})}*/}
                                {/*{inputTextArea({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Pre Items Errors',*/}
                                {/*    name: 'pre_items_errors',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: false,*/}
                                {/*})}*/}
                                {/*{inputTextArea({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Post Items Errors',*/}
                                {/*    name: 'post_items_errors',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: false,*/}
                                {/*})}*/}

                                <div className='integration-options form-group-block'>
                                    <div className='integration-options-title form-group-title'>Integration Options
                                    </div>

                                    {StaticInputSelect({
                                        register,
                                        errors,
                                        label: 'Type',
                                        name: 'machineType',
                                        data: [{name: 'master'}, {name: 'master-slave'}, {name: 'master-slave-slave'}, {name: 'master-slave-slave-slave'}, {name: 'master-slave-slave-slave-slave'}, {name: 'slave'}],
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        name: 'machineToAdminInventory',
                                        label: 'Update Inventory from Machine',
                                        isRequired: false,
                                        placeholder: 'Confirmed',
                                    })}
                                    {InputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        name: 'machineToAdminPlanogram',
                                        label: 'Update Planogram from Machine',
                                        isRequired: false,
                                        placeholder: 'Confirmed',
                                    })}
                                </div>

                                <div className='machine-kiosk form-group-block'>
                                    <div className='machine-kiosk-title form-group-title'>Kiosk Options</div>
                                    {StaticInputSelect({
                                        register,
                                        errors,
                                        label: 'Payment Options',
                                        name: 'payment_options',
                                        data: [{name: 'card'}, {name: 'card-cash'}, {name: 'cash'}],
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        name: 'qr_code_receipt',
                                        label: 'QR Code Receipt',
                                        isRequired: false,
                                        placeholder: 'Confirmed',
                                    })}
                                </div>
                                <div className='machine-status form-group-block'>
                                    <div className='machine-status-title form-group-title'>Status</div>

                                    {InputText({
                                        register,
                                        errors,
                                        label: 'machine status',
                                        name: 'machine_status',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'machine order id processing',
                                        name: 'machine_order_id_processing',
                                        placeholder: '',
                                        isRequired: false,
                                    })}

                                    {InputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        name: 'confirmed',
                                        label: 'Enabled',
                                        isRequired: false,
                                        placeholder: 'Confirmed',
                                    })}
                                    {/*{InputCheckBox({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    watch,*/}
                                    {/*    name: 'blocked',*/}
                                    {/*    label: 'Blocked',*/}
                                    {/*    isRequired: false,*/}
                                    {/*    placeholder: 'Blocked',*/}
                                    {/*})}*/}

                                </div>

                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>                                                                                                                        
                                     
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormMachines
