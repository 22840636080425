import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import EntryEditFormProducts from '../products/EntryEditFormProducts';
import {useTranslation} from "react-i18next";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const MachineRulesBlockEntries = ({
                                      dataProducts,
                                      setValue,
                                      priceRules,
                                      setPriceRules
                                  }) => {
    const {i18n, t} = useTranslation();
    const [activeEntryInfo, setActiveEntryInfo] = useState(null);
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [originalPrices, setOriginalPrices] = useState({}); // Store the original prices

    // Handle input changes by finding the rule by _id
    const handleInputChange = (rule_id, field, value) => {
        const updatedRules = priceRules.map(rule => {
            if (rule._id === rule_id) {
                // Only update the specific field of the matched rule
                return {...rule, [field]: value};
            }
            return rule; // Return the rule unchanged if it doesn't match
        });
        setPriceRules(updatedRules); // Update the state with the new rules array
    };

    // Handle selecting a product from the modal
    const handleProductSelect = (selectedProduct) => {
        if (activeEntryInfo && selectedProduct) {
            setPriceRules((prevPriceRules) => {
                return prevPriceRules.map(rule => {
                    if (rule._id === activeEntryInfo.id) {
                        // Store the original price for this UPC
                        setOriginalPrices(prevOriginalPrices => ({
                            ...prevOriginalPrices,
                            [selectedProduct.barcode]: selectedProduct.price // Set original price
                        }));
                        return {
                            ...rule,
                            upc: selectedProduct.barcode, // Update the UPC for the matched rule
                        };
                    }
                    return rule; // Return the rule unchanged if not matched
                });
            });

            // After updating, reset modal state and active entry info
            setProductModalOpen(false);
            setActiveEntryInfo(null);
        }
    };

    // Add a new rule
    const addRule = () => {
        setPriceRules([
            ...priceRules,
            {
                _id: uuidv4(),
                ruleName: '',
                price: '',
                startDateTime: '',
                endDateTime: '',
                startTime: '',
                endTime: '',
                upc: '',
                ruleset: '',
                repeating_options: 'daily',
                active: true
            },
        ]);
    };

    // Remove a rule
    const removeRule = (index) => {
        const updatedRules = priceRules.filter((_, i) => i !== index);
        setPriceRules(updatedRules);
        setValue('priceRules', updatedRules); // Sync with parent form
    };
    const openModalForRule = (id) => {
        setProductModalOpen(true);
        setActiveEntryInfo({id});
    };

    // Calculate the percentage difference between the original and new price
    const calculatePercentageChange = (originalPrice, newPrice) => {
        if (!originalPrice || !newPrice) return null; // Handle undefined prices
        const percentageChange = ((newPrice - originalPrice) / originalPrice) * 100;
        return percentageChange.toFixed(2); // Return the percentage change with two decimal places
    };

    return (
        <div>
            {/*{console.log(priceRules)}*/}

            {priceRules.map((rule, index) => (
                <div key={rule._id} className="rule-entry-block">

                    <div className="rule-entry">
                        {/*<h5>Rule {index + 1}</h5>*/}
                        <div className="rule-entry-group">
                            <div className="form-group-entry-rule">
                                <label>Rule Name</label>
                                <input
                                    type="text"
                                    value={rule.ruleName}
                                    placeholder="Enter rule name"
                                    className="form-control"
                                    onChange={(e) => handleInputChange(rule._id, 'ruleName', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="rule-entry-group">
                            <div className="form-group-entry-rule">
                                <label>UPC</label>
                                <input
                                    className='upc-entry-input-click-dropdown form-control'
                                    type="text"
                                    name="upc"
                                    value={rule.upc}
                                    placeholder="Click to select product..."
                                    onClick={() => openModalForRule(rule._id)}
                                    readOnly

                                    // onChange={(e) => handleInputChange(index, 'upc', e.target.value)}
                                />
                            </div>
                            {productModalOpen && (
                                <EntryEditFormProducts
                                    isOpen={productModalOpen}
                                    onRequestClose={() => setProductModalOpen(false)}
                                    products={dataProducts.data}
                                    onSelect={handleProductSelect}
                                    i18n={i18n}
                                    t={t}
                                />
                            )}
                        </div>

                        <div className="rule-entry-group">
                            {/* Display original price if available */}
                            {rule.upc && originalPrices[rule.upc] && (
                                <div className="form-group-entry-rule">
                                    <div className="price-entry-original">
                                        <label>Original Price</label>
                                        <p>{originalPrices[rule.upc]} €</p> {/* Show original price */}
                                    </div>
                                </div>
                            )}

                            <div className="form-group-entry-rule ">
                                <label>New Price</label>
                                <input
                                    type="number"
                                    value={rule.price}
                                    placeholder="Set price"
                                    className="form-control price-entry-input-set"
                                    onChange={(e) => handleInputChange(rule._id, 'price', e.target.value)}
                                />
                            </div>

                            {/* Calculate and display percentage change */}
                            {rule.price && originalPrices[rule.upc] && (
                                <div className="form-group-entry-rule">
                                    <label>Price Change</label>
                                    <div className="price-entry-changed">
                                        <p>
                                            {calculatePercentageChange(originalPrices[rule.upc], rule.price)}% {/* Show percentage change */}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div className="rule-entry-group">
                            <div className="form-group-entry-rule">
                                <label>Rule Set</label>
                                <select
                                    className="form-control"
                                    value={rule.ruleset}
                                    onChange={(e) => handleInputChange(rule._id, 'ruleset', e.target.value)}
                                >
                                    <option value="">Select Ruleset</option>
                                    <option value="rule_by_date">Rule by Date</option>
                                    <option value="rule_by_time">Rule by Time</option>
                                </select>
                            </div>

                            {/* Conditionally show date fields for 'rule_by_date' */}
                            {rule.ruleset === 'rule_by_date' && (
                                <>
                                    <div className="form-group-entry-rule">
                                        <label>Start Date & Time</label>
                                        <DatePicker
                                            selected={rule.startDateTime ? new Date(rule.startDateTime) : null}
                                            onChange={(date) => handleInputChange(rule._id, 'startDateTime', date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="form-control"
                                            placeholderText="Select start date & time"
                                        />
                                    </div>

                                    <div className="form-group-entry-rule">
                                        <label>End Date & Time</label>
                                        <DatePicker
                                            selected={rule.endDateTime ? new Date(rule.endDateTime) : null}
                                            onChange={(date) => handleInputChange(rule._id, 'endDateTime', date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="form-control"
                                            placeholderText="Select end date & time"
                                        />
                                    </div>
                                </>
                            )}

                            {/* Conditionally show time fields and repeating options for 'rule_by_time' */}
                            {rule.ruleset === 'rule_by_time' && (
                                <>
                                    <div className="form-group-entry-rule">
                                        <label>Start Time</label>
                                        <input
                                            type="time"
                                            value={rule.startTime}
                                            className="form-control"
                                            onChange={(e) => handleInputChange(rule._id, 'startTime', e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group-entry-rule">
                                        <label>End Time</label>
                                        <input
                                            type="time"
                                            value={rule.endTime}
                                            className="form-control"
                                            onChange={(e) => handleInputChange(rule._id, 'endTime', e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group-entry-rule">
                                        <label>Repeating Option</label>
                                        <select
                                            className="form-control"
                                            value={rule.repeating_options}
                                            onChange={(e) => handleInputChange(rule._id, 'repeating_options', e.target.value)}
                                        >
                                            <option value="daily">Daily</option>
                                            <option value="weekend">Weekend</option>
                                        </select>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="form-group-entry-rule rule-delete-button">

                            <button
                                type="button"
                                className="btn btn-danger delete-price-rule-button"
                                onClick={() => removeRule(index)}
                            >
                                Remove Rule
                            </button>
                        </div>
                    </div>
                </div>

            ))}
            <button type="button" className="btn btn-primary add-price-rule-button" onClick={addRule}>
                Add New Rule
            </button>
        </div>
    );
};

export default MachineRulesBlockEntries;
