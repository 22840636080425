import React from 'react'
import {Button} from "@mui/material";
// import ShoppingContext from '../../context/ShoppingContext'
function Navbar({ showGoBackButton, goBack }) {
    // const {cart} = useContext(ShoppingContext)
    return (
            <div className="navigation-container">
                {showGoBackButton && (
                    <Button onClick={goBack}>
                        <div className="navigation-go-back-button">
                            <img
                                width='50'
                                height='50'
                                src='/img/go_back_icon.png'
                                className='img-fluid brand-logos'
                                alt='goBack'
                            /></div>
                    </Button>
                )}
            </div>
    );
}

export default Navbar;