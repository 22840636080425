import { Spinner, Message } from '../../index';
// import {
//     InputText,
// } from '../../../utils/dynamicForm';
import MachineRulesBlockEntries from "./MachineRulesBlockEntries";

export const FormMachinePriceRules = ({
                                          edit,
                                          formCleanHandler,
                                          isLoading,
                                          register,
                                          isError,
                                          isErrorUpdate,
                                          errors,
                                          setValue,
                                          isLoadingUpdate,
                                          isLoadingPost,
                                          handleSubmit,
                                          submitHandler,
                                          priceRules,
                                          setPriceRules,
                                          error,
                                          productCategoriesData,
                                          watch,
                                          dataProducts,
                                          triggerReset
                                      }) => {


    // const kiosk_external_id = watch('kiosk_external_id');



    return (
        <div
            className='modal fade'
            id='machinePriceRuleModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='machinePriceRuleModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title' id='machinePriceRuleModalLabel'>
                            {edit ? 'Edit Price Rules' : 'Add New Price Rule'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner />
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : isErrorUpdate ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>


                                {/*{InputText({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Machine_id',*/}
                                {/*    name: 'machine_id',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: true,*/}
                                {/*})}*/}
                                {MachineRulesBlockEntries({
                                    setValue,
                                    errors,
                                    watch,
                                    register,
                                    label: 'Items',
                                    name: 'items',
                                    placeholder: '',
                                    isRequired: false,
                                    dataProducts,
                                    productCategoriesData,
                                    triggerReset,
                                    priceRules,
                                    setPriceRules
                                    // initialValue: watch('items'),
                                })}
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm' />
                                        ) : (
                                            'Save'
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormMachinePriceRules;
