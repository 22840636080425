import {createContext, useContext, useEffect, useReducer} from "react";
import dynamicAPI from '../../api/dynamicAPI'; // Assuming this is your custom API function

export const ShoppingContext = createContext();
import { ResetContext } from './ResetContext';

const initialState = {
  products: [],  // Array to store products
  cart: {orderItems: []} ,      // Array to store items in the cart
  isLoading: false, // Boolean to indicate loading state
  error: null,   // To store any error messages
  machine_id: null,
  payment_options: "card",
  enable_recipe: false,
  categories: []
};

// const extractCategories = (products) => {
//   // Assuming each product has a 'category' field
//   const uniqueCategories = [...new Set(products.map(product => product.category))];
//   return uniqueCategories;
// };

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PRODUCTS': {
      // const categories = extractCategories(action.payload);
      const categories = action.payload.categories;
      return {...state, products: action.payload.data, categories, isLoading: false};
    }
    case 'SET_CART':
      return { ...state, cart: action.payload, isLoading: false };
    case 'LOADING':
      return { ...state, isLoading: true };
    case 'INITIALIZE_CART':
      return { ...state, cart: action.payload.cart, payment_options: action.payload.payment_options, enable_recipe: action.payload.enable_recipe, isLoading: false };
    case 'ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'SET_MACHINE_ID':
      // console.log('machine_id_use_effect' + action.payload);
      return { ...state, machine_id: action.payload, isLoading: false  };
    default:
      return state;
  }
}

export const ShoppingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { resetCountdown } = useContext(ResetContext);  // Use resetCountdown from TimerContext

  const fetchProducts = async () => {
    dispatch({ type: 'LOADING' });
    try {
      resetCountdown();

      const response = await dynamicAPI('get', '/api/kiosk/fetchItems?page=1&q=&limit=1000&machine_id='+ state.machine_id);
      // console.log(response);
      dispatch({ type: 'SET_PRODUCTS', payload: response });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
        }
      };

  const fetchCart = async () => {
    try {
      dispatch({ type: 'LOADING' });
      resetCountdown();

      const response = await dynamicAPI('get', '/api/kiosk/fetchCart?machine_id='+ state.machine_id);
      // console.log(response);
      dispatch({ type: 'INITIALIZE_CART', payload: { cart: response.order, payment_options: response.payment_options , enable_recipe: response.qr_code_receipt } });
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };

  // /// LETS CONVERT THIS TO ADD AN ORDER
  // const addProduct = async (formData) => {
  //   try {
  //     dispatch({ type: 'LOADING' });
  //     resetCountdown();
  //
  //     const response = await dynamicAPI('post', '/api/cart/addddproducts?machine_id='+ state.machine_id, { formData });
  //     if (response.status === 200) {
  //       fetchProducts();
  //     } else {
  //       dispatch({ type: 'ERROR', payload: 'Server error during product addition.' });
  //     }
  //   } catch (error) {
  //     dispatch({ type: 'ERROR', payload: error.message });
  //   }
  // };

  const addCartItems = async (item) => {
    try {
      dispatch({ type: 'LOADING' });

      const response = await dynamicAPI(
      'post',
          '/api/kiosk/addCartItems?machine_id='+ state.machine_id,
          {
                item: item,
                machine_id: state.machine_id
              }
      );
      // console.log('SENDING CART ITEMS');
      resetCountdown();

      // console.log(response);

      /// THIS CAN BE IMPROVED - I AM GETTING ON THE RESPONSE THE fetchCart SO THERE IS NO NEED FOR UPDATE
      if (response.status === 200) {
        dispatch({ type: 'SET_CART', payload: response.order });
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during cart item addition.' });
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };



  const removeCartItems = async (ids) => {
    try {
      dispatch({ type: 'LOADING' });
      resetCountdown();

      const response = await dynamicAPI(
          'post',
          '/api/kiosk/removeCartItems?machine_id='+ state.machine_id ,
          {
            items: ids,
            machine_id: state.machine_id
          });
      // console.log(response);
      if (response.status === 200) {
        // For data saving we can have the data on the response
        // fetchCart();
        dispatch({ type: 'SET_CART', payload: response.order });
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during cart item removal.' });
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };

  const onCheckout = async (cart) => {
    try {
      dispatch({ type: 'LOADING' });

      const response = await dynamicAPI(
          'post',
          '/api/kiosk/postOrder?machine_id='+ state.machine_id,
          {
            cart: cart,
            machine_id: state.machine_id
          }
      );
      // console.log('SENDING CART ITEMS');

      // console.log(response);
      resetCountdown();
      if (response.status === 200) {
        // fetchCart();
        dispatch({ type: 'SET_CART', payload: response.order });
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during checkout.' });
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };

  const completeCheckout = async (cart) => {
    try {
      dispatch({ type: 'LOADING' });
      resetCountdown();
      const response = await dynamicAPI(
          'post',
          '/api/kiosk/completeOrder?machine_id='+ state.machine_id,
          {
            cart: cart,
            machine_id: state.machine_id
          }
      );
      // console.log('SENDING CART ITEMS');

      // console.log(response);

      if (response.status === 200) {
        // fetchCart();
        dispatch({ type: 'SET_CART', payload: response.order });
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during checkout.' });
        return { success: false, message: 'Server error during checkout.' };
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };

  const closeCheckout = async (cart) => {
    try {
      dispatch({ type: 'LOADING' });
      resetCountdown();

      const response = await dynamicAPI(
          'post',
          '/api/kiosk/closeOrder?machine_id='+ state.machine_id,
          {
            cart: cart,
            machine_id: state.machine_id
          }
      );
      // console.log('SENDING CART ITEMS');

      // console.log(response);

      if (response.status === 200) {
        // fetchCart();
        dispatch({ type: 'SET_CART', payload: response.order });
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during checkout.' });
        return { success: false, message: 'Server error during checkout.' };
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };

  const transactionCheck = async (cart) => {
    try {
      dispatch({ type: 'LOADING' });
      console.log('checking payment');
      const response = await dynamicAPI(
          'post',
          '/api/kiosk/transactionCheck?machine_id='+ state.machine_id,
          {
            cart: cart,
            machine_id: state.machine_id
          }
      );
      // console.log('SENDING CART ITEMS');


      if (response.status === 200) {
        // fetchCart();
        console.log('setting cart');
        dispatch({ type: 'SET_CART', payload: response.order });
        return response.order;
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during checkout.' });
        return { success: false, message: 'Server error during checkout.' };
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };


  const setKioskState = async (status) => {
    try {
      dispatch({ type: 'LOADING' });
      console.log('sending state');
      const response = await dynamicAPI(
          'post',
          '/api/kiosk/setKioskState?machine_id='+ state.machine_id,
          {
            machine_status: status,
            machine_id: state.machine_id
          }
      );
      // console.log('SENDING CART ITEMS');
      resetCountdown();


      if (response.status === 200) {
        // fetchCart();
        console.log('state set');
        return true;
      } else {
        dispatch({ type: 'ERROR', payload: 'Server error during checkout.' });
      }
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message });
    }
  };


  // RESET THE PAGE
  useEffect(() => {
    let interval = null;
    if (state.countdown > 0) {
      interval = setInterval(() => {
        console.log(state.countdown);
        dispatch({ type: 'UPDATE_COUNTDOWN' });
      }, 1000);
    } else if (state.countdown === 0) {
      console.log("Countdown finished. Refreshing the page or triggering an event.");
      window.location.href = `/kiosk/machine/${state.machine_id}`; // Sets the URL to the desired path
      window.location.reload(); // Refreshes the page
    }

    return () => clearInterval(interval);
  }, [state.countdown]);


  // LOAD CART AND PRODUCTS ONLY AFTER state.machine_id IS DEFINED
  useEffect(() => {
    if (state.machine_id) {
      fetchProducts();
      fetchCart();
      resetCountdown();
    }
  }, [state.machine_id]);

  return (
    <ShoppingContext.Provider value={{
      ...state,
      dispatch,
      fetchProducts,
      fetchCart,
      addCartItems,
      removeCartItems,
      onCheckout,
      closeCheckout,
      completeCheckout,
      transactionCheck,
      setKioskState
    }}>
      {children}
    </ShoppingContext.Provider>
  );
};

export default ShoppingContext;