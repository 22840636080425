import React, {useContext, useState} from 'react';
import CartItemModal from './CartItemModal';
import ShoppingContext from "../../context/ShoppingContext";
import CheckoutCountdownDisplay from './CheckoutCountdownDisplay';
import { CheckoutContext } from '../../context/CheckoutContext';
const CartModal = ({cart, onClose, i18n, t}) => {


    const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
    const [isWaitingForDispense, setIsWaitingForDispense] = useState(false);
    const [isWaitingForComplete, setIsWaitingForComplete] = useState(false);
    const [isWaitingForPaymentOption, setIsWaitingForPaymentOption] = useState(false);
    const [transactionMessage, setTransactionMessage] = useState('');
    const [transactionActions, setTransactionActions] = useState('');
    const [qrCodeImage, setQrCodeImage] = useState('');
    const [loadingContent, setLoadingContent] = useState('');

    const {onCheckout, closeCheckout, completeCheckout, transactionCheck, machine_id, payment_options, enable_recipe} = useContext(ShoppingContext)
    const { startCheckoutCountdown, resetCheckoutCountdown, stopCheckoutCountdown , getCheckoutCountdown} = useContext(CheckoutContext);
    // New state to track the previous credit value
    const [previousCredit, setPreviousCredit] = useState(0);

    // useEffect(() => {
    //     if (cart.cartState === "ORDERCREATED") {
    //         setIsWaitingForPayment(true);
    //         setIsWaitingForDispense(true);
    //     }
    // }, [cart.cartState]); // Dependency array with cart.status
    const handleCheckout = async () => {
        startCheckoutCountdown(80);
        if (payment_options === 'card-cash') {
            setIsWaitingForPaymentOption(true);
            // Display the case based on the payment options before proceeding
            setTransactionMessage(t('Choose your payment method: card or cash.'));
            setTransactionActions(
                <div className="cart-block-button-checkout-inner">
                    <div className="cart-payment-button" onClick={proceedWithCardPayment}>
                        <div className="cart-payment-image">
                            <img
                                width='240'
                                height='240'
                                src='/img/pay_with_card.png'
                                className='img-fluid payment-icon'
                                alt='card-select'
                            />
                        </div>
                        <div className="cart-payment-button-title">
                            {t("CARD")}
                        </div>
                    </div>
                    <div className="cart-payment-button" onClick={proceedWithCashPayment}>
                        <div className="cart-payment-image">
                            <img
                                width='240'
                                height='240'
                                src='/img/coin_slot_hand.jpg'
                                className='img-fluid payment-icon'
                                alt='cash-select'
                            />
                        </div>
                        <div className="cart-payment-button-title">
                            {t("CASH")}
                        </div>
                    </div>
                    <div className="cart-block-button-checkout-close">
                        <div className="cart-return-button-payment" onClick={closePopupCheckoutAndSave}>{t("Return")}</div>
                    </div>
                </div>
            )
        } else {
            // Default behavior if no special payment options
            setIsWaitingForPayment(true);
            setIsWaitingForDispense(true);
            await onCheckout(cart);
            await checkPaymentStatus();
        }
    };

    const proceedWithCardPayment = async () => {
        setIsWaitingForPaymentOption(false);
        setIsWaitingForPayment(true);
        setIsWaitingForDispense(true);
        resetCheckoutCountdown();
        await onCheckout({ ...cart, paymentMethod: 'CC' });
        await checkPaymentStatus('CC');
    };

    const proceedWithCashPayment = async () => {
        setIsWaitingForPaymentOption(false);
        setIsWaitingForPayment(true);
        setIsWaitingForDispense(true);
        resetCheckoutCountdown();
        await onCheckout({ ...cart, paymentMethod: 'CASH' });
        await checkPaymentStatus('CASH');
    };


    const closePopupCheckoutAndComplete = () => {
        setIsWaitingForPaymentOption(false);
        setIsWaitingForPayment(false);
        setIsWaitingForDispense(false);
        setIsWaitingForComplete(false);
        setTransactionMessage('');
        setTransactionActions('');
        setQrCodeImage('');
        setLoadingContent('');
        stopCheckoutCountdown();
        completeCheckout(cart);
        onClose();
    }

    const closePopupCheckoutAndSave = () => {
        setIsWaitingForPaymentOption(false);
        setIsWaitingForPayment(false);
        setIsWaitingForDispense(false);
        setIsWaitingForComplete(false);
        setTransactionMessage('');
        setTransactionActions('');
        setQrCodeImage('');
        setLoadingContent('');
        stopCheckoutCountdown();
        closeCheckout(cart);
        onClose();
    }

    const checkPaymentStatus = async () => {
        const pollingInterval = 3000; // 3 seconds
        let elapsedTime = 0;

        console.log('LETS CHECK PAYMENT ');


        const poll = async () => {
            try {

                console.log("checkPoll" + elapsedTime);
                const remainingTime = getCheckoutCountdown();
                console.log("remainingTimer" + remainingTime);
                // Check if countdown has reached zero
                if (remainingTime <= 0) {
                    // ORDER TIME OUT
                    console.log('STOP TRYING');
                    closeCheckout(cart);
                    setLoadingContent(<img src='/img/payment_error.png' alt='Error'/>);
                    setTransactionMessage(t('Timeout occurred during payment.'));
                    setTransactionActions(
                        <div className="cart-block-button-checkout-inner">
                            <div className="cart-return-button" onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                        </div>
                    );
                    stopCheckoutCountdown(); // Stop countdown in context
                    return;
                }

                // COLLECT DATA
                const transaction = await transactionCheck(cart);
                console.log("TransactionCheck Response");
                // console.log(transaction);


                // INITIATED THE ORDER
                if (transaction.transactionStatus === "ORDERCREATED") {
                    setIsWaitingForPayment(true);
                    setIsWaitingForDispense(true);
                    setIsWaitingForComplete(true);
                    console.log('ORDERCREATED');
                    setLoadingContent(
                        <div>
                            <div className="loading-spinner"></div>
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p>{t("Παρακαλώ περιμένετε...")}</p>

                        </div>
                    );
                    setTransactionMessage(t("We are processing your order"));
                    setTransactionActions('');
                    elapsedTime += pollingInterval;
                    setTimeout(poll, pollingInterval);
                    return;
                }

                if (transaction.qr_code_data) {
                    // setQrCodeImage(transaction.qr_code_data);
                    console.log(transaction.qr_code_data);
                    setQrCodeImage(
                        <div>
                            <div className="cart-receipt-image">
                                <img
                                    width='420'
                                    height='420'
                                    src={`data:image/png;base64,${transaction.qr_code_data}`}
                                    className='img-fluid payment-icon'
                                    alt='QR Code'
                                />
                            </div>
                            <div className="cart-receipt-text">{t("YOUR RECEIPT")}</div>
                        </div>
                    );
                }

                // ORDER COMPLETED
                if (transaction.transactionStatus === "COMPLETED") {
                    console.log('COMPLETED');
                    setLoadingContent(
                        <div>
                            <div className="loading-spinner"></div>
                            <div className="lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <p className="order-thankyou-text">{t("Ευχαριστούμε για την προτίμηση σας")}</p>
                        </div>
                    );
                    setTransactionMessage("....");
                    setTransactionActions('');

                    // Redirect to home page after 5 seconds
                    setTimeout(() => {
                        setIsWaitingForComplete(false);
                        window.location.href = `/kiosk/machine/${machine_id}`; // Sets the URL to the desired path
                        window.location.reload(); // Refreshes the page
                    }, 5000); // 5000 milliseconds = 5 seconds

                    return;
                }


                console.log('CHECK IF PAYMENT');
                if (transaction.transactionStatus === "PAYMENT") {

                    console.log('PAYMENT PROCESS');

                    switch (transaction.paymentProcess) {
                        case 'SUCCESS':
                            console.log('SUCCESS');

                            setLoadingContent(
                                <img
                                    width='240'
                                    height='240'
                                    src='/img/tick-icon.png'
                                    className='img-fluid payment-icon'
                                    alt='Success'
                                />
                            );
                            setTransactionMessage(t('Payment successful'));
                            setIsWaitingForPayment(false);
                            resetCheckoutCountdown();
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'FAILED':
                        case 'PAYMENTERROR':
                            console.log('PAYMENTERROR');

                            setLoadingContent(
                                <img
                                    width='240'
                                    height='240'
                                    src='/img/payment_error.png'
                                    className='img-fluid payment-icon'
                                    alt='logo'
                                />
                            );
                            setTransactionMessage(t('Payment failed'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                                </div>
                            );
                            // setIsWaitingForPayment(false);
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'RUNNING':
                            console.log('RUNNING');

                            if (transaction.paymentMethod === "CASH") {
                                // Monitor changes in credit value
                                if (transaction?.machine_current_cash?.credit !== previousCredit) {
                                    resetCheckoutCountdown();  // Reset countdown when credit changes
                                    setPreviousCredit(transaction.machine_current_cash.credit);  // Update previous credit
                                }
                                setLoadingContent(
                                    <div>

                                        <div className="loading-payment-title">{t("Pay by cash")}</div>
                                        <img
                                            width='340'
                                            height='340'
                                            src='/img/coin_slot_hand.jpg'
                                            className='img-fluid payment-icon'
                                            alt='logo'
                                        />
                                        {/* Check if transaction.credit exists even if it's zero */}
                                        {typeof transaction.totalPrice !== 'undefined' && (
                                            <div className="credit-info">
                                                <div className="credit-info-need">
                                                    {t("Amount to pay")}: {transaction.totalPrice}€
                                                </div>
                                                <div className="credit-info-given">
                                                    {t("Amount inserted")}: {transaction.machine_current_cash.credit}€
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                                setTransactionMessage(t("Please insert your money into the slot"));
                                setTransactionActions(
                                    <div className="cart-block-button-checkout-inner">
                                        <div className="cart-return-button" onClick={closePopupCheckoutAndSave}>{t("Cancel")}</div>
                                    </div>
                                );
                            } else {

                                setLoadingContent(
                                    <div>

                                        <div className="loading-payment-title">{t("Πληρωμή με κάρτα")}</div>
                                        <img
                                            width='240'
                                            height='240'
                                            src='/img/payment_loading.png'
                                            className='img-fluid payment-icon'
                                            alt='logo'
                                        />

                                    </div>
                                );
                                setTransactionMessage(t("Place your card or mobile on the POS surface to pay"));
                            }
                            elapsedTime += pollingInterval;
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'PROCESSING':
                            console.log('PROCESSING');
                            setLoadingContent(
                                <div>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <p>{t("Παρακαλώ περιμένετε...")}</p>

                                </div>
                            );
                            setTransactionMessage(t("Your payment is in progress"));
                            setTransactionActions('');
                            elapsedTime += pollingInterval;
                            setTimeout(poll, pollingInterval);
                            break;
                        default:
                            console.log('DEFAULT');
                            setLoadingContent(
                                <img
                                    width='240'
                                    height='240'
                                    src='/img/sad_face.png'
                                    className='img-fluid payment-icon'
                                    alt='logo'
                                />
                            );
                            setTransactionMessage(t('Unexpected Payment Status'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                                </div>
                            );
                            // setIsWaitingForPayment(false);
                            setTimeout(poll, pollingInterval);
                            break;
                    }
                }


                console.log("CHECK IF DISPENSE");
                if (transaction.transactionStatus === "DISPENSE") {
                    console.log('DISPENSE PROCESS');

                    switch (transaction.dispenseProcess) {
                        case 'COMPLETED':
                            console.log('COMPLETED');
                            setLoadingContent(
                                <img
                                    width='240'
                                    height='240'
                                    src='/img/tick-icon.png'
                                    className='img-fluid dispense-icon'
                                    alt='Success'
                                />
                            );
                            setTransactionMessage(t('Dispensing successful'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndComplete}>{t("Close")}</div>
                                </div>
                            );
                            resetCheckoutCountdown();
                            setIsWaitingForComplete(true);
                            setIsWaitingForDispense(false);
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'SUCCESS':
                            console.log('SUCCESS');
                            setLoadingContent(
                                <img
                                    width='240'
                                    height='240'
                                    src='/img/tick-icon.png'
                                    className='img-fluid dispense-icon'
                                    alt='Success'
                                />
                            );
                            setTransactionMessage(t('Dispensing successful'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndComplete}>{t("Close")}</div>
                                </div>
                            );
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'FAIL':
                            console.log('FAIL');
                            setLoadingContent(<img src='/img/payment_error.png' alt='Error'/>);
                            setTransactionMessage(t('Dispensing failed'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                                </div>
                            );
                            setIsWaitingForDispense(false);
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'RUNNING':
                            console.log('RUNNING');
                            setLoadingContent(
                                <div>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            );
                            setTransactionMessage(transaction.transactionStatusMessage || t("We are preparing your items"));
                            setTransactionActions('');
                            elapsedTime += pollingInterval;
                            setTimeout(poll, pollingInterval);
                            break;
                        case 'TAKE':
                            console.log('TAKE');
                            setLoadingContent(
                                <div>
                                    <img
                                        width='240'
                                        height='240'
                                        src='/img/dispense_items.png'
                                        className='img-fluid dispense-icon'
                                        alt='Success'
                                    />
                                    <p>-</p>
                                    <div className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            );
                            setTransactionMessage(transaction.transactionStatusMessage || t("Please take your items"));
                            setTransactionActions('');
                            elapsedTime += pollingInterval;
                            setTimeout(poll, pollingInterval);
                            break;
                        default:
                            console.log('DEFAULT');
                            setLoadingContent(<img src='/img/payment_error.png' alt='Error'/>);
                            setTransactionMessage(t('Unexpected status'));
                            setTransactionActions(
                                <div className="cart-block-button-checkout-inner">
                                    <div className="cart-return-button"
                                         onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                                </div>
                            );
                            setIsWaitingForDispense(false);
                            setTimeout(poll, pollingInterval);
                            break;
                    }

                }


            } catch (error) {
                console.log(error);
                setLoadingContent(
                    <div>
                        <img
                            width='240'
                            height='240'
                            src='/img/payment_error.png'
                            className='img-fluid transaction-icon'
                            alt='logo'
                        />
                        <p>{error}</p>
                    </div>
                );


                setTransactionMessage(t('Error occurred during dispense check'));
                setTransactionActions(
                    <div className="cart-block-button-checkout-inner">
                        <div className="cart-return-button" onClick={closePopupCheckoutAndSave}>{t("Close")}</div>
                    </div>
                );
                setIsWaitingForPayment(false);
                setTimeout(poll, pollingInterval);
            }
        };

        poll();
    };

    console.log('cart')
    console.log(cart);


    return (
        <div className="cart-modal">

            <div className="cart-modal-backdrop">
                <div className="cart-modal-content">
                    <div className="cart-modal-title">
                        <div className="cart-modal-title-inner">{t("Your cart")}</div>
                    </div>
                    <div className="cart-modal-items">
                        {cart.orderItems.map((item, index) => (
                            <CartItemModal
                                item={item}
                                key={`${item.productId}-${index}`}
                                i18n={i18n}
                            />
                        ))}
                    </div>
                    <div className="cart-modal-total">
                        <div className="cart-modal-total-text">
                            {t("Order-Total")}
                        </div>
                        <div className="cart-modal-total-price">
                            {cart.totalPrice.toFixed(2)}€
                        </div>
                    </div>
                    <div className="cart-modal-actions">
                        <div className="cart-block-button-inner">
                            <div className="cart-clear-button"
                                 onClick={closePopupCheckoutAndSave}>{t("continue-shopping")}</div>
                        </div>
                        <div className="cart-block-button-inner">
                            <div className="cart-order-button" onClick={handleCheckout}>{t("order-paying")}</div>
                        </div>
                    </div>

                </div>
            </div>
            {(isWaitingForPayment || isWaitingForDispense || isWaitingForComplete || isWaitingForPaymentOption) && (
                <div className="checkout-block">

                    <div className="transaction-block">
                        <CheckoutCountdownDisplay />

                        <div className="transaction-content">
                            {loadingContent}
                        </div>

                        <div className="transaction-message">
                            {t(transactionMessage)}
                        </div>
                        {enable_recipe && (
                            <div className="transaction-qrcodeimage">
                                {qrCodeImage}
                            </div>
                        )
                        }
                        <div className="transaction-actions">
                            {transactionActions}
                        </div>


                        {/*<p>Order ID: {cart.order_id}</p>*/}
                        {/*<p>Cart orderTime: {cart.orderTime}</p>*/}
                        {/*<p>Cart State: {cart.cartState}</p>*/}
                        {/*<p>Transaction Status: {cart.transactionStatus}</p>*/}
                        {/*<p>Transaction Status Message: {cart.transactionStatusMessage}</p>*/}
                        {/*<p>Payment Process: {cart.paymentProcess}</p>*/}
                        {/*<p>Dispense Process: {cart.dispenseProcess}</p>*/}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartModal;