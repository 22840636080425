import moment from 'moment'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

export const ViewProducts = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete,
  useTranslation
 // images_path
}) => {
  // console.log(data);
  const {i18n, t} = useTranslation(); // Use i18n instance to access current language


  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <table className='table table-sm table-border edit-grid-view'>
        <thead className='border-0'>
        <tr>
          <th>Status</th>
          <th></th>
          <th>{t("Created")}</th>
          <th>Barcode</th>
          <th>{t("Name")}</th>
          {/*<th>Items Errors</th>*/}
          {/*<th>Pre Items</th>*/}
          {/*<th>Post Items</th>*/}
          {/*<th>Pre Items Errors</th>*/}
          {/*<th>Post Items Errors</th>*/}
          <th>{t("Actions")}</th>
        </tr>
        </thead>

        <tbody>
        {data && data.data ? (
            data.data.map((product) => (
                <tr key={product._id}
                    className={(product.title === '' || product.date === '' || product.address === '') ? 'empty-values' : ''}>
                  <td>
                    {product.confirmed ? (
                        <FaCheckCircle className='text-success'/>
                    ) : (
                        <FaTimesCircle className='text-danger'/>
                    )}
                  </td>
                  <td className='list-item-thumbnail'>
                    {product.images.length > 0 ? (
                        <td className="product-list-thumbnail"><img
                            src={`${data.images_path}${product.images[0]['path']}`} alt="Thumbnail"/></td>
                    ) : (
                        <td className="product-list-thumbnail"><img src={`${data.images_path}imall-item-no-image.png`}
                                                                    alt="Default Thumbnail"/></td>
                    )}
                  </td>
                  <td>{moment(product.createdAt).format('lll')}</td>
                  <td>{product.barcode}</td>
                  <td>{product.name?.[i18n.language] ?? product.name?.el}</td>

                  {/*<td>{JSON.stringify(product.items)}</td>*/}
                  {/*<td>{JSON.stringify(product.items_errors)}</td>*/}
                  {/*<td>{JSON.stringify(product.pre_items)}</td>*/}
                  {/*<td>{JSON.stringify(product.post_items)}</td>*/}
                  {/*<td>{JSON.stringify(product.pre_items_errors)}</td>*/}
                  {/*<td>{JSON.stringify(product.post_items_errors)}</td>*/}

                  <td>
                    <div className='btn-group'>
                      <button
                          className='btn'
                          onClick={() => editHandler(product)}
                          data-bs-toggle='modal'
                          data-bs-target='#productModal'
                      >
                        <img src={EditItemIcon} alt="Edit"
                             className="icon-img action-icons-img"/>
                      </button>

                      <button
                          className='btn'
                          onClick={() => deleteHandler(product._id)}
                          disabled={isLoadingDelete}
                      >
                        {isLoadingDelete ? (
                            <span className='spinner-border spinner-border-sm'/>
                        ) : (
                            <span>
                            <img src={DeleteItemIcon} alt="Delete" className="icon-img action-icons-img"/>
                        </span>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
            ))
        ) : (
            // Render an error message when data is undefined or has no data
            <tr>
              <td colSpan="8">Error: Failed to fetch product data</td>
            </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default ViewProducts
