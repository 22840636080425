import React, {useContext, useState} from 'react'
import ShoppingContext from '../../context/ShoppingContext'
import CartItem from './CartItem'
// import {FaShoppingCart} from 'react-icons/fa'
import { useTranslation } from 'react-i18next';
import CartModal from './CartModal';

function Cart() {
    const {cart, removeCartItems} = useContext(ShoppingContext)
    const {i18n, t } = useTranslation();

    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const openCartModal = () => setIsCartModalOpen(true);
    const closeCartModal = () => setIsCartModalOpen(false);


    // Function to handle clearing the cart
    const clearCart = () => {
        const productIds = cart.orderItems.map(item => item.productId);
        removeCartItems(productIds); // Call the context function with the array of IDs
    };

    // console.log(cart);
    // Check if cart and cart.orderItems are defined and is an array
    if (!cart || !Array.isArray(cart.orderItems)) {
        return <div className="cart-block-no-items">
            <div className="cart-block-no-items-cart-title">{t('No items in cart')}</div>
        </div>;
    }
    // If cart is not defined, or cart.orderItems is not an array, or it's empty, don't render anything
    if (!cart || !Array.isArray(cart.orderItems) || cart.orderItems.length === 0) {
        return null; // Return null to render nothing
    }

    // const getTotalCartQuantity = () => {
    //     // Ensure cart.orderItems is defined and is an array before proceeding
    //     if (Array.isArray(cart.orderItems)) {
    //         // Use reduce to sum up the quantity of each cart item
    //         return cart.orderItems.reduce((totalQuantity, cartItem) => {
    //             // Ensure cartItem.quantity is a number to avoid NaN errors
    //             const itemQuantity = Number(cartItem.quantity) || 0;
    //             return totalQuantity + itemQuantity;
    //         }, 0);
    //     }
    //     // Return 0 if cart.orderItems is not an array or is undefined
    //     return 0;
    // };


  return (

    <div className="cart-container">
            {isCartModalOpen && (
                <CartModal
                    cart={cart}
                    onClose={closeCartModal}
                    i18n={i18n}
                    t={t}
                />
            )}
        {/* Render cart-container-header and cart-items-block only when CartModal is not open */}
        {!isCartModalOpen && (
            <>
                <div className="cart-container-header">
                    <div className="cart-title">{t('Your order')}</div>
                    {/*<div className="cart-container-cart-badge">*/}
                    {/*    <FaShoppingCart className="cart-icon"/>*/}
                    {/*    <div className="badge">{getTotalCartQuantity()}</div>*/}
                    {/*</div>*/}
                    <div className="cart-container-total">
                        {/*<div className="cart-container-total-text">*/}
                        {/*    {t("Order-Total")}*/}
                        {/*</div>*/}
                        <div className="cart-container-total-price">
                            {cart.totalPrice.toFixed(2)}€
                        </div>
                    </div>
                </div>

                <div className="cart-items-block">
                    {cart.orderItems.map((item, index) => {
                        const key = `${item.productId}-${index}`;
                        return <CartItem
                            item={item}
                            key={key}
                            i18n={i18n}
                            t={t}
                        />;
                    })}
                </div>
            </>
        )}

            <div className="cart-block">
                <div className="cart-block-button-inner">
                    <div className="cart-clear-button" onClick={clearCart}>{t("Clear")}</div>
                </div>
                <div className="cart-block-button-inner">
                <div className="cart-checkout-button" onClick={openCartModal}>{t("Cart")}</div>
            </div>
        </div>
    </div>
  )
}

export default Cart