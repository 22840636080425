import React, { useContext, useEffect, useState } from 'react';
import { ResetContext } from '../../context/ResetContext';
import '../../css/ResetCountdownDisplay.css';

function ResetCountdownDisplay() {
    const { getCountdown } = useContext(ResetContext);
    const [countdown, setCountdown] = useState(getCountdown());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(getCountdown());
        }, 1000);

        return () => clearInterval(interval);
    }, [getCountdown]);

    return (
        <div className="reset-countdown-display">
            {`${countdown}s`}
        </div>
    );
}

export default ResetCountdownDisplay;
